import React from "react";
import styled from 'styled-components';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    }; 
  }

  render(props) {
    const { status } = this.state;
    return (
      <HtmlForm
        onSubmit={this.submitForm}
        action={"https://formspree.io/" + this.props.formid}
        method="POST"
      >
        <input type="text" name="name" placeholder="Your name" required/>
        <input type="email" name="email" placeholder="Your email address" required />
        <textarea name="message" placeholder={'Your ' + this.props.messagelabel} required />
        <input type="text" name="_gotcha" style={{display: `none`}} />
        
        {status === "SUCCESS" ? <p><br/><strong>Thanks for your message, we will get back to you soon!</strong></p> : <button>Submit</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </HtmlForm>
 
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

const HtmlForm = styled.form`
  margin: 40px auto;
  
  input[type="text"],
  input[type="email"] {
    background: none;
    border-width: 0 0 2px 0;
    border-color: #3c7db6;
    color: #fff;
    outline: none;
  }

  textarea {
    background: none;
    border-color: #3c7db6;
    color: #fff;
    outline: none;
    border-width: 0 0 2px 0;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;
  }
  
`